<template>
  <staff-company-instruction-per-module
    :key="$route.query.type"
  />
</template>
<script>
import StaffCompanyInstructionPerModule
  from "@/components/modules/company-instruction/staff/StaffCompanyInstructionPerModule.vue";
export default {
  name: "StaffCompanyInstruction",
  components : {StaffCompanyInstructionPerModule}
}
</script>

<style scoped>

</style>
